import React from "react";
import CanvaClone from "../../components/CanvaClone/CanvaClone.js";

const MoreCustomScreen = () => {
  return (
    <div className="App">
      <CanvaClone />
    </div>
  );
};

export default MoreCustomScreen;
